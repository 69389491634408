<template>
  <div>
	  <!-- 员工 新增修改表单 -->
    <a-form-model
      title="1d2"
      layout="vertical"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 18 }"
    >
      <div class="drawer-title">基本信息</div>
      <a-divider></a-divider>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="真实姓名" prop="actualName">
            <a-input v-model="form.actualName" placeholder="请输入真实姓名" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="性别" prop="sex">
            <a-select v-model="form.sex" placeholder="请选择性别">
              <a-select-option v-for="item in options.sex" :key="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="身份证号" prop="idCard">
            <a-input v-model="form.idCard" placeholder="请输入身份证号" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item ref="tel" label="手机号" prop="tel">
            <a-input
              v-model="form.tel"
              placeholder="请输入手机号"
              @blur="
                () => {
                  $refs.tel.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="出生日期" prop="birthday">
            <a-date-picker  v-model="form.birthday" value-format="YYYY-MM-DD HH:mm:ss"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="密码" prop="password">
            <a-input
              type="password"
              placeholder="不输入默认为手机号后6位"
              v-model="form.password"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="电子邮箱" prop="email">
            <a-input placeholder="请输入电子邮箱" v-model="form.email" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="居住地址" prop="address">
            <a-input placeholder="请输入居住地址" v-model="form.address" />
          </a-form-model-item>
        </a-col>
        身份证照片
        <a-upload
          :action="`${$upload}`"
          :headers="uploadHeaders"
          list-type="picture-card"
		  accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
          :file-list="idcardfileList"
          @preview="handlePreview"
          @change="idcardhandleChange"
		  :beforeUpload='beforeUpload'
        >
          <div v-if="idcardfileList.length < 2">
            <!-- <a-icon type="plus" /> -->
			<a-icon :type="imgLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">点击上传</div>
          </div>
        </a-upload>
		<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
        <span style="color: #00000073"
          >单张最多不超过4M，最多上传2张，支持JPG,PNG格式</span
        >
      </a-row>
      <div class="drawer-title">入职信息</div>
      <a-divider></a-divider>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="岗位" prop='cascVal'>
             <a-cascader
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'childList',
              }"
              :options="treeData"
              placeholder="请选择"
              @change="onChange"
              v-model="form.cascVal"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="入职时间" prop="entryDate">
            <a-date-picker
              placeholder="请选择入职时间"
              v-model="form.entryDate"
              value-format="YYYY-MM-DD HH:mm:ss"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="薪资" prop="salary">
            <a-input placeholder="请输入薪资" v-model="form.salary" />
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div>上传资料</div>
          <a-upload
            :action="`${$upload}`"
            :headers="uploadHeaders"
			accept='.jpg,.JPG,.png,.PNG,.pdf,.PDF'
            list-type="picture"
			:file-list="fileList"
			@preview="handlePreview"
          	@change="fileChange"
			:beforeUpload='beforeUpload'
          >
            <a-button> <a-icon type="upload"/> 点击上传 </a-button>
          </a-upload>
          <span style="color: #00000073">支持上传PNG，JPG，PDF文件</span>
        </a-col>
      </a-row>
      <div class="drawer-title">联系信息</div>
      <a-divider></a-divider>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="紧急联系人" prop="emergencyContact">
            <a-input
              placeholder="请输入紧急联系人"
              v-model="form.emergencyContact"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="紧急联系电话" prop="emergencyContactNumber">
            <a-input
              placeholder="请输入紧急联系电话"
              v-model="form.emergencyContactNumber"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item
            label="紧急联系地址"
            prop="emergencyContactAddress"
          >
            <a-input
              placeholder="请输入紧急联系地址"
              v-model="form.emergencyContactAddress"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="drawer-title">其他信息</div>
      <a-divider></a-divider>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="婚姻状态" prop="maritalStatus">
            <a-select
              placeholder="请选择婚姻状态"
              v-model="form.maritalStatus"
           >
		    <a-select-option v-for="item in options.maritalStatus" :key="item.id">{{
                item.name
              }}</a-select-option></a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="民族" prop="nation">
            <a-input placeholder="请输入民族" v-model="form.nation"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="政治面貌" prop="politicalOutlook">
            <a-select
              placeholder="请选择政治面貌"
              v-model="form.politicalOutlook"
            >
			<a-select-option v-for="item in options.politicalOutlook" :key="item.id">{{
                item.name
              }}</a-select-option></a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="户口类型" prop="accountType">
            <a-select placeholder="请选择类型" v-model="form.accountType">
				<a-select-option v-for="item in options.accountType" :key="item.id">{{
                item.name
              }}</a-select-option>
			</a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="备注" prop="remake">
            <a-textarea
              v-model="form.remake"
              placeholder="请输入备注"
            ></a-textarea>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { form, rules, options, uploadHeaders } from "./config"
import { insertManageUser, departmentList, manageUserInfo, EditManageUser } from "@/api/basic/department";
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}
export default {
	name: "Vueform",
	props: {
		type: {
			type: String,
			default:'add'
		},
		editId:Number,
	},
	data() {
		return {
			form,
			rules,
			options,
			uploadHeaders,
			previewVisible: false,
			previewImage: "",
			imgLoading: false,
			treeData:[],	//岗位数据
			telephoneNum:"", //修改手机号判断用
			idcardfileList: [], //身份证照片数组
			fileList:[],  //资料数据
		};
	},
	created() {
		this.getData()
	},
	methods: {
		getData(){
			departmentList().then((res) => {
				this.treeData = res.data;
				// type 1部门 2岗位
				this.positionDisabled(this.treeData)
			});
		},
		// 部门禁选
		positionDisabled(arr){
			arr.forEach((item,index)=>{
				if(item.type=== 1 && (item.childList.length===0 || item.childList===null)){
					arr[index].disabled=true
				}else if(item.type === 2){
					arr[index].disabled=false
				}else{
					this.positionDisabled(item.childList)
				}
			})
		},
		// 提交
		submit() {
			let arr = []
			let file = []
			for(let item of this.idcardfileList){
				// 新增的照片
				if(item.response){
					arr.push(item.response.data)
				}else{
				// 已添加的照片
					arr.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			for(let item of this.fileList){
				// 新增的照片
				if(item.response){
					file.push(item.response.data)
				}else{
				// 已添加的照片
					file.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			this.form.idCardImgUrls = arr
			this.form.dataImgUrls = file
			this.$refs.ruleForm.validate((valid) => {
				if (!valid) {
					return;
				}
				if (this.type === "add") {
					insertManageUser(this.form).then((res) => {
						if(res.code===200){
							this.$message.success(res.msg)
							this.$emit('success')
							this.close()
						}
					});
				} else {
					if(this.form.tel === this.telephoneNum){
						this.form.tel = null
					}
					// console.log(this.form);
					EditManageUser(this.form).then((res) => {
						if(res.code===200){
							this.$message.success(res.msg)
							this.$emit('success')
							this.close()
						}
					});
				}
			});
		},
		// 关闭新增员工
		close() {
			this.$refs.ruleForm.resetFields();
			this.form.cascVal = []
			this.idcardfileList =[]
			this.fileList =[]
		},
		async handlePreview(file) {
			console.log(file);
			if(file.name.indexOf('pdf')!==-1){
				if(file.response){
					window.open(this.$ImgUrl(file.response.data))
					return
				}else{
					window.open(file.url)
					return
				}
			}
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		// 选择岗位
		onChange(data) {
			// data [1,2,2]
			// yyyy-mm-dd hh:mm:ss
			let _data = JSON.parse(JSON.stringify(data))
			this.form.positionId = _data.pop()   //2
			this.form.departmentId = _data[_data.length-1]  //[1,2]==>2
			let str = ''
			// 1:2:
			for (let i = 0; i < _data.length; i++) {
				str += _data[i]+':'
			}
			// 0:1:2:
			this.form.departmentIdPath = '0:'+ str
		},
		handleCancel() {
			this.previewVisible = false;
		},
		idcardhandleChange(info) {
			this.idcardfileList = info.fileList
		},
		fileChange(info) {
			this.fileList = info.fileList
		},
		beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
	},
	watch: {
		editId: {
			handler(val) {
				// console.log(val);
				if(val === null){
					return
				}
				this.form.id =val;
				manageUserInfo({id:this.form.id}).then(res=>{
					let data = res.data
					// 编辑回显
					this.form.actualName = data.actualName
					this.form.sex = data.sex
					this.form.idCard = data.idCard
					this.form.tel = data.tel
					this.telephoneNum = data.tel
					this.form.birthday = data.birthday
					this.form.password = data.password
					this.form.email = data.email
					this.form.address = data.address
					this.form.entryDate = data.entryDate
					this.form.salary = data.salary
					this.form.emergencyContact = data.emergencyContact
					this.form.emergencyContactNumber = data.emergencyContactNumber
					this.form.emergencyContactAddress = data.emergencyContactAddress
					this.form.maritalStatus = data.maritalStatus
					this.form.nation = data.nation
					this.form.politicalOutlook = data.politicalOutlook
					this.form.accountType = data.accountType
					this.form.remake = data.remake
					// 岗位
					this.form.cascVal = []
					let path = data.departmentIdPath.split(':')
					path.pop()
					path.splice(0,1)
					path.push(data.positionId)
					path.forEach(element => {
						this.form.cascVal.push(Number(element))
					});
					// 图片
					// 身份证
					if(!data.idCardImgList.length){
						this.idcardfileList=[]
					}
					const arr = []
					for(let item of data.idCardImgList){
						let obj = {
							name:item.url.split('_')[0],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
						}
						arr.push(obj)
					}
					this.idcardfileList = arr
					//资料
					if(!data.dataImgList.length){
						this.fileList=[]
					}
					const file = []
					for(let item of data.dataImgList){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						file.push(obj)
					}
					this.fileList = file
				})
			},
			immediate: true,
		},
	},
};
</script>

<style lang="less">
.drawer-title {
	margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
	margin-top: 16px
}
</style>