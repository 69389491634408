<template>
  <div class="main">
    <a-row>
      <!-- 组织架构 -->
      <a-col :span="5" class="left-tree">
        <!-- <a-input-search
          style="margin-bottom: 8px"
          placeholder="请输入搜索信息"
          v-model="searchStr"
          @change="onSearch"
        /> -->
        <div>
          <a-button type="link" @click="openDepartment(1)"
            >+&nbsp;新建分组</a-button
          >
          <a-button type="link" icon="form" @click="openDepartment()">
          </a-button>
          <a-button
            type="link"
            style="color: #ff4d4f"
            icon="delete"
            @click="del()"
          >
          </a-button>
        </div>
        <a-tree
          v-model="checkedKeys"
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @select="onSelect"
          :replaceFields="{ children: 'childList', title: 'name' }"
        >
          <template slot="title" slot-scope="{ name }">
            <span
              v-html="
                name.replace(
                  new RegExp(searchValue, 'g'),
                  '<span style=color:#f50>' + searchValue + '</span>'
                )
              "
            ></span>
          </template>
        </a-tree>
      </a-col>
      <!-- right 员工 -->
      <a-col :span="19">
        <div class="search-box">
          <a-space size="large">
            <a-input v-model="searchForm.keyword" placeholder="请输入姓名/手机号" />
            <a-button type="primary" @click='getData'>查 询</a-button>
            <a-button @click='reset'>重 置</a-button>
          </a-space>
        </div>
        <!-- 表格 -->
        <div id="commonTable">
          <div style="margin-bottom: 16px">
            <a-space size="large">
              <a-button class='add-btn' :loading="loading" @click="addStaff()">
                新增员工
              </a-button>
			  <a-upload name="file" :action="`${$excelUpload}`"
                    accept=".xls,.XLS,.xlsx,.XLSX" :showUploadList="false"
                	:headers="uploadHeaders" @change="changeFile">
                    <a-button><a-icon type="upload" /> 批量导入</a-button>
              </a-upload>
			  <a-button @click="modelDownload">
				  <a-icon type="copy" />模版下载
			  </a-button>
              <a-button :disabled="!hasSelected" :loading="loading">
                批量操作
              </a-button>
            </a-space>
            <span style="margin-left: 8px">
              <template v-if="hasSelected">
                {{ `已选择 ${selectedRowKeys.length} 条` }}
              </template>
            </span>
          </div>
          <a-table
            :columns="columns"
            :data-source="tableData"
            :pagination="pagination"
            @change="handleTableChange"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :row-key="
              (record, index) => {
                return record.id;
              }
            "
          >
            <!-- 操作 -->
            <span slot="action" slot-scope="text,scope">
              <a-space size="small">
                <a class="ant-dropdown-link" @click="edit(scope)">编辑</a>
				<a-switch checked-children="开" un-checked-children="关" @change="enableUser(scope.id)" :checked='scope.status ===1'></a-switch>
                <a class="ant-dropdown-link" @click="resetPsw(scope.id)">重置密码</a>
              </a-space>
            </span>
            <span slot="formatter" slot-scope="scope">{{
              scope === 1 ? "可" : ""
            }}</span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <!-- 添加部门 -->
    <a-modal
      :title="show.depepartmentTitle"
      :visible="show.addDepartment"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form-model
          layout="vertical"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item
            prop="parentId"
            label="上级"
            v-show="show.depepartmentTitle === '新增组织架构'"
          >
            <a-cascader
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'childList',
              }"
              :options="treeData"
              placeholder="无上级"
              change-on-select
              @change="onChange"
              v-model="cascVal"
            />
          </a-form-model-item>
          <a-form-model-item prop="type" label="类型">
            <a-select v-model="form.type">
              <a-select-option
                v-for="item in typeOption"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="name" label="部门/岗位名称">
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 添加员工信息 -->
    <a-drawer
      :title="show.addStaffTitle"
      :width="720"
      :visible="show.addStaffShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="addStaffClose"
    >
      <div class="drawer-content">
        <vue-form :type="type" ref="addform" @success="success" :editId='editId'></vue-form>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addStaffClose">
          关闭
        </a-button>
        <a-button type="primary" @click="addStaffSubmit"> 提交 </a-button>
      </div>
    </a-drawer>
    <!-- 重置密码 -->
     <a-drawer
      title="重置密码"
      :width="500"
      :visible="show.resetPswShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="repswClose"
    >
      <div class="drawer-content">
        <a-row>
          <a-col :span="8">
            新密码
          </a-col>
          <a-col :span="16">
            <a-input style='width:90%' v-model="password.newPassword" type='password'/>
          </a-col>
          <a-col :span="8" style='margin-top:20px'>
          确认密码
          </a-col>
          <a-col :span="16"  style='margin-top:20px'>
            <a-input style='width:90%' v-model="password.confirmpsw" type='password'/>
          </a-col>
        </a-row>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="repswClose">
          关闭
        </a-button>
        <a-button type="primary" @click="rePswSubmit"> 提交 </a-button>
      </div>
    </a-drawer>
	<a-modal title="批量导入操作成功" :visible="uploadVisible" @cancel="uploadVisible = false" :footer="null">
		<div>以下为导入失败的用户</div>
		<p v-for="(item,index) in uploadResult" :key="index">
			{{item}}
		</p>
	</a-modal>
  </div>
</template>
<script>
import store from "@/store";
import { columns, pagination, searchForm } from "./depend/config.js";
import vueForm from "./depend/form.vue";
import {
	departmentList,
	departmentInsert,
	departmentUpdate,
	departmentDel,
	findToUser,
	rePassword,
	userEnable
} from "@/api/basic/department";

export default {
	components: {
		vueForm,
	},
	data() {
		return {
			columns,
			pagination,
			searchForm,
			searchStr: "",
			searchValue: "",
			rules: {
				type: [{ required: true, message: "请选择类型", trigger: "change" }],
				name: [{ required: true, message: "请输入名称", trigger: "blur" }],
			},
			cascVal: [],
			typeOption: [
				{
					id: 1,
					name: "公司/部门",
				},
				{
					id: 2,
					name: "岗位",
				},
			],
			form: {
				parentId: 0,
				type: null,
				name: "",
			},
			editId:null,
			password:{
				id:null,
				newPassword:'',
				confirmpsw:''
			},
			choose: {
				id: undefined,
			},
			show: {
				addStaffShow: false,
				addDepartment: false,
				resetPswShow:false,
				addStaffTitle:'添加员工信息',
				depepartmentTitle: "",
			},
			expandedKeys: [],
			autoExpandParent: true,
			backupsExpandedKeys: [],
			checkedKeys: [],
			selectedKeys: [],
			treeData: [],
			//   数据
			tableData: [],
			//   分页
			loading: false,
			selectedRowKeys: [],
			type: "add",
			// 文件上传
			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
			//上传对话框
			uploadVisible: false,
			uploadResult: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			departmentList().then((res) => {
				this.treeData = res.data;
			});
			findToUser({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			}).then((res) => {
				this.tableData = res.data.rows;
				this.pagination.total = res.data.total
			});
		},
		openDepartment(type) {
			if (type === 1) {
				this.show.depepartmentTitle = "新增组织架构";
				this.show.addDepartment = true;
			} else {
				if (this.choose.id !== undefined) {
					this.show.depepartmentTitle = "修改组织架构";
					this.show.addDepartment = true;
					this.form.id = this.choose.id;
					this.form.name = this.choose.name;
					this.form.type = this.choose.type;
				} else {
					this.$message.error("请选择组织架构");
				}
			}
		},
		// 新增组织架构
		handleOk() {
			if (this.show.depepartmentTitle === "新增组织架构") {
				departmentInsert(this.form).then((res) => {
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
						this.handleCancel();
					} else {
						this.$message.error(res.msg);
					}
				});
			} else {
				departmentUpdate({
					id: this.form.id,
					type: this.form.type,
					name: this.form.name,
				}).then((res) => {
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
						this.handleCancel();
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		},
		del() {
			if (this.choose.id === undefined) {
				this.$message.error("请选择组织架构");
			} else {
				this.$confirm({
					title: "是否删除",
					icon: "delete",
					onOk: () => {
						departmentDel({ id: this.choose.id, type: this.choose.type }).then(
							(res) => {
								if (res.code === 200) {
									this.$message.success(res.msg);
									this.getData();
								} else {
									this.$message.error(res.msg);
								}
							}
						);
					},
				});
			}
		},
		async enableUser(id){
			const res = await userEnable({manageUserId:id})
			if(res.code === 200){
				this.$message.success(res.msg)
				this.getData()
			}else{
				this.$message.error(res.msg)
			}
		},
		handleCancel() {
			this.show.addDepartment = false;
			this.$refs.ruleForm.resetFields();
			this.cascVal = [];
		},
		success() {
			this.addStaffClose();
			this.getData();
		},
		reset(){
			this.searchForm.keyword=''
			this.getData();
		},
		// 新增员工
		addStaff() {
			this.type = 'add'
			this.show.addStaffShow = true;
		},
		edit(row){
			this.show.addStaffShow = true;
			this.type = 'edit'
			this.editId = row.id
			this.show.addStaffTitle = "修改员工信息"
		},
		addStaffSubmit() {
			this.$refs.addform.submit();
		},
		addStaffClose() {
			this.editId =null
			this.$refs.addform.close();
			this.show.addStaffShow = false;
		},
		resetPsw(id){
			this.show.resetPswShow = true
			this.password.id = id
		},
		rePswSubmit() {
			if(this.password.newPassword !== this.password.confirmpsw){
				this.$message.error('两次输入密码不同')
				return
			}
			rePassword(this.password).then(res=>{
				if(res.code === 200){
					this.$message.success(res.msg)
					this.repswClose()
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		repswClose(){
			this.show.resetPswShow = false
			this.password  = {
				id:null,
				newPassword:'',
				confirmpsw:''
			}
		},
		onExpand(expandedKeys) {
			this.expandedKeys = expandedKeys;
			this.autoExpandParent = false;
		},
		onCheck(checkedKeys) {
			this.checkedKeys = checkedKeys;
		},
		onSelect(selectedKeys, info) {
			this.selectedKeys = selectedKeys;
			this.searchForm.departmentId = null
			this.searchForm.positionId = null

			if (info.selectedNodes.length !== 0) {
				this.choose.id = info.selectedNodes[0].data.props.id;
				this.choose.name = info.selectedNodes[0].data.props.name;
				this.choose.type = info.selectedNodes[0].data.props.type;
				if(this.choose.type  === 1){
					this.searchForm.departmentId = this.choose.id
					this.getData()
				}else if(this.choose.type === 2){
					this.searchForm.positionId = this.choose.id
					this.getData()
				}
			} else {
				this.choose = {};
				this.getData()
			}
		},
		detail() {
			this.$router.push({ name: "settle_detail" });
		},
		// 表格selection
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		// 级联选择上级
		onChange(data) {
			let thatId = data.slice(-1);
			this.form.parentId = thatId[0];
		},
		onSearch() {
			var vm = this;
			//添加这行代码是为了只点击搜索才触发
			vm.searchValue = vm.searchStr;
			//如果搜索值为空，则不展开任何项，expandedKeys置为空
			//如果搜索值不位空，则expandedKeys的值要为搜索值的父亲及其所有祖先
			if (vm.searchValue === "") {
				vm.expandedKeys = [];
			} else {
				//首先将展开项与展开项副本置为空
				vm.expandedKeys = [];
				vm.backupsExpandedKeys = [];
				//获取所有存在searchValue的节点
				let candidateKeysList = vm.getkeyList(vm.searchValue, vm.treeData, []);
				//遍历满足条件的所有节点
				candidateKeysList.map((item) => {
					//获取每个节点的母亲节点
					var key = vm.getParentKey(item, vm.treeData);
					//当item是最高一级，父key为undefined，将不放入到数组中
					//如果母亲已存在于数组中，也不放入到数组中
					if (key && !vm.backupsExpandedKeys.some((item) => item === key))
						vm.backupsExpandedKeys.push(key);
				});
				let length = this.backupsExpandedKeys.length;
				for (let i = 0; i < length; i++) {
					vm.getAllParentKey(vm.backupsExpandedKeys[i], vm.treeData);
				}
				vm.expandedKeys = vm.backupsExpandedKeys.slice();
			}
		},
		//获取节点中含有value的所有key集合
		getkeyList(value, tree, keyList) {
			//遍历所有同一级的树
			for (let i = 0; i < tree.length; i++) {
				let node = tree[i];
				//如果该节点存在value值则push
				if (node.name.indexOf(value) > -1) {
					keyList.push(node.key);
				}
				//如果拥有孩子继续遍历
				if (node.childList) {
					this.getkeyList(value, node.childList, keyList);
				}
			}
			//因为是引用类型，所有每次递归操作的都是同一个数组
			return keyList;
		},
		//该递归主要用于获取key的父亲节点的key值
		getParentKey(key, tree) {
			let parentKey, temp;
			//遍历同级节点
			for (let i = 0; i < tree.length; i++) {
				const node = tree[i];
				if (node.childList) {
					//如果该节点的孩子中存在该key值，则该节点就是我们要找的父亲节点
					//如果不存在，继续遍历其子节点
					if (node.childList.some((item) => item.key === key)) {
						parentKey = node.key;
					} else if ((temp = this.getParentKey(key, node.childList))) {
						//parentKey = this.getParentKey(key,node.children)
						//改进，避免二次遍历
						parentKey = temp;
					}
				}
			}
			return parentKey;
		},
		//获取该节点的所有祖先节点
		getAllParentKey(key, tree) {
			var parentKey;
			if (key) {
				//获得父亲节点
				parentKey = this.getParentKey(key, tree);
				if (parentKey) {
					//如果父亲节点存在，判断是否已经存在于展开列表里，不存在就进行push
					if (!this.backupsExpandedKeys.some((item) => item === parentKey)) {
						this.backupsExpandedKeys.push(parentKey);
					}
					//继续向上查找祖先节点
					this.getAllParentKey(parentKey, tree);
				}
			}
		},
		//批量导入
		changeFile(data) {
			if(data.file.status == "done") {
				if(data.file.response.success == false) {
					this.$message.error(data.file.response.msg);
				} else {
					this.$message.success('操作成功');
					if(data.file.response.data) {
						this.uploadResult = data.file.response.data;
						this.uploadVisible = true;
					}
					this.getData();
				}
			}
		},
		beforeUpload() {

		},
		//模版下载
		modelDownload() {
			window.open('https://saas.kaidalai.cn/excelModel/员工导入模版.xlsx');
		}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
	watch: {
		checkedKeys(val) {
			console.log("onCheck", val);
		},
	},
};
</script>
<style lang="less" scoped>
.left-tree {
  padding: 10px;
  border-right: 1px solid #0000000f;
  height: 100vh;
}
#commonTable {
  padding: 10px;
}
</style>
