/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "真实名称",
		dataIndex: "actualName",
		width: "12%",
	},
	{
		title: "手机号",
		dataIndex: "tel",
		width: "14%",
	},
	{
		title: "所属部门",
		dataIndex: "departmentName",
		width: "16%",
	},
	{
		title: "岗位",
		dataIndex: "positionName",
		width: "10%",
	},
	{
		title: "状态",
		dataIndex: "status",
		width: "12%",
		customRender: function (status) {
			switch (status) {
			case 1:
				return "正常";
			case 2:
				return "停用";
			default:
				break;
			}
		},
	},
	{
		title: "备注",
		dataIndex: "remake",
		width: "300",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
];
// 搜索项
export const searchForm = {
	keyword: "",
	departmentId: null,
	positionId: null,
};
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
};
export const form = {
	cascVal: [],
	id: null,
	actualName: "",
	sex: undefined,
	idCard: "",
	tel: "",
	birthday: "",
	password: "",
	email: "",
	address: "",
	idCardImgUrls: [],
	departmentId: "",
	departmentIdPath: "",
	positionId: "",
	entryDate: "",
	salary: "",
	dataImgUrls: [],
	emergencyContact: "",
	emergencyContactNumber: "",
	emergencyContactAddress: "",
	maritalStatus:undefined,
	nation: "",
	politicalOutlook:undefined,
	accountType:undefined,
	remake: "",
};

const handleConfirm = (rule,value,callback) => {
	if(!value){
		callback();
	  }else{
		// 正则判断失败抛出错误，否则直接callback()
		if(!/(^\d{18}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)){
		  callback(new Error("身份证格式不正确!"));
		}else{
		  callback();
		}
	}
}

export const rules = {
	actualName: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
	sex: [{ required: true, message: "请选择性别", trigger: "change" }],
	idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" },{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
	tel: [
		{ required: true, message: "请输入手机号", trigger: "blur" },
		{ min: 11, max: 11, message: "手机号只能为11位", trigger: "blur" },
	],
	cascVal: [
		{ type: "array", required: true, message: "请选择岗位", trigger: "change" },
	],
	entryDate: [{ required: true, message: "请选择入职时间", trigger: "change" }],
	// emergencyContactNumber: [
	// 	{ required: true, message: "请输入手机号", trigger: "blur" },
	// 	{ min: 11, max: 11, message: "手机号只能为11位", trigger: "blur" },
	// ],
};
export const options = {
	sex: [
		{
			id: 1,
			name: "男",
		},
		{
			id: 2,
			name: "女",
		},
		{
			id: 3,
			name: "保密",
		},
	],
	maritalStatus:[
		{
			id: 1,
			name: "未婚",
		},
		{
			id: 2,
			name: "已婚",
		},
	],
	politicalOutlook:[
		{
			id: 1,
			name: "群众",
		},
		{
			id: 2,
			name: "中共党员",
		},
		{
			id: 3,
			name: "共青团",
		},
		{
			id: 4,
			name: "民革党员",
		},
		{
			id: 5,
			name: "民盟盟员",
		},
		{
			id: 6,
			name: "民建会员",
		},
		{
			id: 7,
			name: "民进会员",
		},
		{
			id: 8,
			name: "农工党党员",
		},
		{
			id: 9,
			name: "致公党党员",
		},
		{
			id: 10,
			name: "九三学社社员",
		},
		{
			id: 11,
			name: "台盟盟员",
		},
		{
			id: 12,
			name: "无党派人士",
		},
	],
	accountType: [
		{
			id: 1,
			name: "农村",
		},
		{
			id: 2,
			name: "城市",
		},
	]
};
import store from "@/store";
export const uploadHeaders = {
	"manage-login-token": store.getters.getToken,
};
